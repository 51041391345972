// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as $$Date from "../../../libs/Date.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Table from "../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../styleguide/icons/IconMenu.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TableRow from "../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as TableHeaderCell from "../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as ResourcesOptions from "./ResourcesOptions.res.js";
import * as DashboardIndexCss from "../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as DashboardTableCss from "../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardFilterCss from "../../../styleguide/dashboard/components/filters/DashboardFilterCss.res.js";
import * as ResourceDashboardNavbar from "../resource-dashboard-navbar/ResourceDashboardNavbar.res.js";
import * as ResourcesDashboardActionBar from "./ResourcesDashboardActionBar.res.js";
import * as DashboardDeleteConfirmationModalCss from "../../../styleguide/dashboard/components/modals/deleteConfirmation/DashboardDeleteConfirmationModalCss.res.js";

var initialState_sortBy = {
  NAME: "Title",
  VAL: "Asc"
};

var initialState_selection = ID.$$Set.make();

var initialState_categoryFilter = ID.$$Set.make();

var initialState_entityUpdates = ID.$$Map.make();

var initialState = {
  status: "FetchingResources",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  selection: initialState_selection,
  categoryFilter: initialState_categoryFilter,
  entityUpdates: initialState_entityUpdates,
  batchUpdate: undefined,
  batchUpdateError: false,
  openDestroyConfirm: false,
  currentResourceId: undefined
};

function ResourcesDashboard$default(props) {
  var categories = props.categories;
  var container = React.useRef(null);
  var searchResources = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchResources" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api.fetchResourcesDashboard(state.currentPage, tmp, state.sortBy, Belt_Set.toList(state.categoryFilter)), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedResourcesFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ResourcesDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ResourcesDashboard.default"
                                          }, "ResourcesDashboard::FetchResources::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailResourcesFetch");
                                    }));
                            })
                        };
              case "FailResourcesFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedResourcesFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          }
                        };
              case "SortByTitle" :
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var match$1 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$1 === "object" && match$1.NAME === "Title" && match$1.VAL !== "Desc" ? ({
                                  NAME: "Title",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Title",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              case "SortByCategory" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var match$3 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$3 === "object" && match$3.NAME === "Category" && match$3.VAL !== "Desc" ? ({
                                  NAME: "Category",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Category",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              case "SortByCreatedAt" :
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var match$5 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$5 === "object" && match$5.NAME === "CreatedAt" && match$5.VAL !== "Desc" ? ({
                                  NAME: "CreatedAt",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "CreatedAt",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              case "SortByPopular" :
                  var match$6 = state.status;
                  if (typeof match$6 !== "object" && match$6 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var match$7 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$7 === "object" && match$7.NAME === "Popular" && match$7.VAL !== "Desc" ? ({
                                  NAME: "Popular",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Popular",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              case "ToggleSelectionOfAllItems" :
                  var match$8 = state.status;
                  var match$9 = state.batchUpdate;
                  if (typeof match$8 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$9 !== undefined) {
                    return "NoUpdate";
                  }
                  var resources = match$8._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: resources.length !== Belt_Set.size(state.selection) ? Belt_Set.fromArray(Belt_Array.map(resources, (function (resource) {
                                          return resource.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          }
                        };
              case "DeselectAll" :
                  var match$10 = state.status;
                  var match$11 = state.batchUpdate;
                  if (typeof match$10 !== "object" || match$11 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentResourceId: state.currentResourceId
                            }
                          };
                  }
              case "ActivateInBatch" :
                  var resources$1 = state.status;
                  if (typeof resources$1 !== "object") {
                    return "NoUpdate";
                  }
                  var resources$2 = resources$1._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Activating",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var resourceIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (resource) {
                                                          return Caml_obj.equal(id, resource.id);
                                                        }), __x);
                                          })(resources$2);
                                      if (match !== undefined && !match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api.activateResourcesInBatch(resourceIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedActivateInBatch",
                                                    _0: resourceIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ResourcesDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ResourcesDashboard.default"
                                          }, "ResourcesDashboard" + "::ActivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "DeactivateInBatch" :
                  var resources$3 = state.status;
                  if (typeof resources$3 !== "object") {
                    return "NoUpdate";
                  }
                  var resources$4 = resources$3._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Deactivating",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var resourceIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (resource) {
                                                          return Caml_obj.equal(id, resource.id);
                                                        }), __x);
                                          })(resources$4);
                                      if (match !== undefined && match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api.deactivateResourcesInBatch(resourceIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedDeactivateInBatch",
                                                    _0: resourceIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ResourcesDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ResourcesDashboard.default"
                                          }, "ResourcesDashboard" + "::DeactivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "FailInBatchAction" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: undefined,
                            batchUpdateError: true,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          }
                        };
              case "CloseDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: false,
                            currentResourceId: undefined
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedResourcesFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.resources
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$12 = state.status;
                  if (typeof match$12 !== "object" && match$12 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingResources",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentResourceId: state.currentResourceId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchResources");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: searchResources
                        };
              case "PerformSearch" :
                  var match$13 = state.status;
                  if (typeof match$13 !== "object" && match$13 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingResources",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentResourceId: state.currentResourceId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchResources");
                              })
                          };
                  }
              case "ToggleSelectionOfItem" :
                  var id = action._0;
                  var match$14 = state.batchUpdate;
                  if (match$14 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: Belt_Set.has(state.selection, id) ? Belt_Set.remove(state.selection, id) : Belt_Set.add(state.selection, id),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentResourceId: state.currentResourceId
                            }
                          };
                  }
              case "ToggleCategoryFilter" :
                  var categoryId = action._0;
                  var match$15 = state.status;
                  if (typeof match$15 !== "object" && match$15 === "FetchingResources") {
                    return "NoUpdate";
                  }
                  var categoryFilter = Belt_Set.has(state.categoryFilter, categoryId) ? Belt_Set.remove(state.categoryFilter, categoryId) : Belt_Set.add(state.categoryFilter, categoryId);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResources",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            categoryFilter: categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResources");
                            })
                        };
              case "UpdateActivityActive" :
                  var action$1 = action._1;
                  var resourceId = action._0;
                  var match$16 = state.status;
                  var match$17 = state.batchUpdate;
                  var match$18 = Belt_Map.get(state.entityUpdates, resourceId);
                  if (typeof match$16 !== "object") {
                    match$16 === "FetchingResources";
                  } else if (match$17 === undefined && match$18 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$16._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, resourceId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Activate" ? true : false;
                                        return {
                                                id: x.id,
                                                title: x.title,
                                                resourceImage: x.resourceImage,
                                                slug: x.slug,
                                                resourceDetail: x.resourceDetail,
                                                resourceSummary: x.resourceSummary,
                                                popular: x.popular,
                                                categorySlug: x.categorySlug,
                                                pdfFile: x.pdfFile,
                                                privateDownload: x.privateDownload,
                                                createdAt: x.createdAt,
                                                category: x.category,
                                                active: tmp,
                                                metaTitle: x.metaTitle,
                                                metaDescription: x.metaDescription,
                                                resourceCategoryId: x.resourceCategoryId,
                                                pdfResource: x.pdfResource,
                                                leads: x.leads
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              categoryFilter: state.categoryFilter,
                              entityUpdates: Belt_Map.set(state.entityUpdates, resourceId, {
                                    TAG: "ActivityUpdate",
                                    _0: action$1
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentResourceId: state.currentResourceId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Activate") {
                                  return $$Promise.wait(Api.activateResource(resourceId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActivityActiveUpdate",
                                                              _0: resourceId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "ResourcesDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "ResourcesDashboard.default"
                                                    }, "ResourcesDashboard" + "::UpdateStatus::Activate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActivityActiveUpdate",
                                                      _0: resourceId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api.deactivateResource(resourceId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActivityActiveUpdate",
                                                              _0: resourceId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "ResourcesDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "ResourcesDashboard.default"
                                                    }, "ResourcesDashboard" + "::UpdateStatus::Disable::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActivityActiveUpdate",
                                                      _0: resourceId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedActivityActiveUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          }
                        };
              case "FailActivityActiveUpdate" :
                  var resourceId$1 = action._0;
                  var match$19 = state.status;
                  var match$20 = Belt_Map.get(state.entityUpdates, resourceId$1);
                  if (typeof match$19 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$20 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$20._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$19._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, resourceId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Activate" ? true : false;
                                      return {
                                              id: x.id,
                                              title: x.title,
                                              resourceImage: x.resourceImage,
                                              slug: x.slug,
                                              resourceDetail: x.resourceDetail,
                                              resourceSummary: x.resourceSummary,
                                              popular: x.popular,
                                              categorySlug: x.categorySlug,
                                              pdfFile: x.pdfFile,
                                              privateDownload: x.privateDownload,
                                              createdAt: x.createdAt,
                                              category: x.category,
                                              active: tmp,
                                              metaTitle: x.metaTitle,
                                              metaDescription: x.metaDescription,
                                              resourceCategoryId: x.resourceCategoryId,
                                              pdfResource: x.pdfResource,
                                              leads: x.leads
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, resourceId$1),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentResourceId: state.currentResourceId
                          }
                        };
              case "SucceedActivateInBatch" :
                  var ids = action._0;
                  var resources$5 = state.status;
                  if (typeof resources$5 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(resources$5._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids)) {
                                          return {
                                                  id: x.id,
                                                  title: x.title,
                                                  resourceImage: x.resourceImage,
                                                  slug: x.slug,
                                                  resourceDetail: x.resourceDetail,
                                                  resourceSummary: x.resourceSummary,
                                                  popular: x.popular,
                                                  categorySlug: x.categorySlug,
                                                  pdfFile: x.pdfFile,
                                                  privateDownload: x.privateDownload,
                                                  createdAt: x.createdAt,
                                                  category: x.category,
                                                  active: true,
                                                  metaTitle: x.metaTitle,
                                                  metaDescription: x.metaDescription,
                                                  resourceCategoryId: x.resourceCategoryId,
                                                  pdfResource: x.pdfResource,
                                                  leads: x.leads
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentResourceId: state.currentResourceId
                            }
                          };
                  }
              case "SucceedDeactivateInBatch" :
                  var ids$1 = action._0;
                  var resources$6 = state.status;
                  if (typeof resources$6 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(resources$6._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids$1)) {
                                          return {
                                                  id: x.id,
                                                  title: x.title,
                                                  resourceImage: x.resourceImage,
                                                  slug: x.slug,
                                                  resourceDetail: x.resourceDetail,
                                                  resourceSummary: x.resourceSummary,
                                                  popular: x.popular,
                                                  categorySlug: x.categorySlug,
                                                  pdfFile: x.pdfFile,
                                                  privateDownload: x.privateDownload,
                                                  createdAt: x.createdAt,
                                                  category: x.category,
                                                  active: false,
                                                  metaTitle: x.metaTitle,
                                                  metaDescription: x.metaDescription,
                                                  resourceCategoryId: x.resourceCategoryId,
                                                  pdfResource: x.pdfResource,
                                                  leads: x.leads
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              categoryFilter: state.categoryFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentResourceId: state.currentResourceId
                            }
                          };
                  }
              case "OpenDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            categoryFilter: state.categoryFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: true,
                            currentResourceId: Caml_option.some(action._0)
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchResources");
        }), []);
  var len = categories.length;
  var tmp;
  if (len !== 1 && len !== 0) {
    var x = Belt_Set.size(state.categoryFilter);
    tmp = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: DashboardFilterCss.dropdown,
                  children: x !== 0 ? (
                      x !== 1 ? String(x) + " Categories" : "1 Category"
                    ) : "All Categories"
                }),
            JsxRuntime.jsx(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardFilterCss.dropdownBody,
                  children: Belt_Array.map(categories, (function (category) {
                          var id = "resource-categories-filter--category-" + ID.toString(category.id);
                          return JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Checkbox.make, {
                                              id: id,
                                              size: "SM",
                                              checked: Belt_Set.has(state.categoryFilter, category.id),
                                              onChange: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleCategoryFilter",
                                                        _0: category.id
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx("label", {
                                              children: category.title,
                                              className: DashboardFilterCss.label,
                                              htmlFor: id
                                            })
                                      ],
                                      className: DashboardFilterCss.dropdownBodyRow
                                    }, id);
                        }))
                })
          ]
        });
  } else {
    tmp = null;
  }
  var resources = state.status;
  var tmp$1;
  if (typeof resources !== "object") {
    tmp$1 = false;
  } else {
    var resources$1 = resources._0;
    tmp$1 = Belt_Set.size(state.selection) === resources$1.length && resources$1.length !== 0;
  }
  var match$1 = state.sortBy;
  var len$1 = categories.length;
  var tmp$2;
  if (len$1 !== 1 && len$1 !== 0) {
    var match$2 = state.sortBy;
    tmp$2 = JsxRuntime.jsx(Control.make, {
          onClick: (function (param) {
              dispatch("SortByCategory");
            }),
          children: JsxRuntime.jsxs("span", {
                children: [
                  "Category",
                  JsxRuntime.jsx(IconSorting.make, {
                        size: "XXS",
                        direction: typeof match$2 === "object" && match$2.NAME === "Category" ? match$2.VAL : undefined
                      })
                ],
                className: DashboardTableCss.controlCell
              })
        });
  } else {
    tmp$2 = null;
  }
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var resources$2 = state.status;
  var tmp$3;
  tmp$3 = typeof resources$2 !== "object" ? (
      resources$2 === "FetchingResources" ? JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Spinner.make, {
                    size: "XL",
                    color: "Teal"
                  }),
              className: DashboardTableCss.spinner
            }) : JsxRuntime.jsx("div", {
              children: "Something went wrong during the search",
              className: DashboardTableCss.fetchError
            })
    ) : Belt_Array.map(resources$2._0, (function (resource) {
            var leadsDisabled = resource.leads === 0;
            var leadsLinkDisabled = leadsDisabled ? DashboardTableCss.disabled : "";
            var len = categories.length;
            var active = resource.active;
            return JsxRuntime.jsxs(TableRow.make, {
                        children: [
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx(Checkbox.make, {
                                      id: "resource--" + (ID.toString(resource.id) + "--selected"),
                                      size: "SM",
                                      checked: Belt_Set.has(state.selection, resource.id),
                                      onChange: (function (param) {
                                          dispatch({
                                                TAG: "ToggleSelectionOfItem",
                                                _0: resource.id
                                              });
                                        })
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx(Link.make, {
                                      href: Routes_Resource.Dashboard.edit(resource.id),
                                      children: resource.title
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: len !== 1 && len !== 0 ? JsxRuntime.jsx("div", {
                                        children: resource.category
                                      }) : null
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: $$Date.Naive.format(resource.createdAt, "MM/dd/yyyy")
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: resource.popular ? "True" : "False"
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx(Toggle.make, {
                                      on: active,
                                      size: "MD",
                                      onChange: (function () {
                                          dispatch({
                                                TAG: "UpdateActivityActive",
                                                _0: resource.id,
                                                _1: active ? "Disable" : "Activate"
                                              });
                                        })
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs(Popover.make, {
                                            children: [
                                              JsxRuntime.jsx(Popover.Trigger.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          children: JsxRuntime.jsx(IconMenu.make, {
                                                                size: "MD",
                                                                color: "Teal"
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsxs(Dropdown.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "RightEdge"
                                                    },
                                                    className: DashboardFilterCss.dropdownBody,
                                                    children: [
                                                      JsxRuntime.jsx(Control.make, {
                                                            className: DashboardFilterCss.dropdownBodyRow,
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: Routes_Resource.Dashboard.edit(resource.id),
                                                                  className: DashboardFilterCss.dropdownBodyLink,
                                                                  children: "Edit"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx(Control.make, {
                                                            className: DashboardFilterCss.dropdownBodyRow,
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: "/resources/" + (resource.categorySlug + ("/" + resource.slug)),
                                                                  className: DashboardFilterCss.dropdownBodyLink,
                                                                  children: "View"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx(Control.make, {
                                                            className: DashboardFilterCss.dropdownBodyRow,
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: resource.privateDownload,
                                                                  targetBlank: true,
                                                                  className: DashboardFilterCss.dropdownBodyLink,
                                                                  children: "Download PDF"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx(Control.make, {
                                                            className: Cx.cx([
                                                                  DashboardFilterCss.dropdownBodyRow,
                                                                  leadsLinkDisabled
                                                                ]),
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: leadsDisabled ? "#" : Routes_Resource.leadsCsv(resource.id),
                                                                  className: DashboardFilterCss.dropdownBodyLink,
                                                                  children: "Download Leads"
                                                                })
                                                          }),
                                                      JsxRuntime.jsx(Control.make, {
                                                            className: DashboardFilterCss.dropdownBodyRow,
                                                            children: JsxRuntime.jsx(A.make, {
                                                                  href: "#",
                                                                  className: DashboardFilterCss.dropdownBodyLink,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "OpenDestroyConfirm",
                                                                            _0: resource.id
                                                                          });
                                                                    }),
                                                                  children: "Delete"
                                                                })
                                                          })
                                                    ]
                                                  })
                                            ]
                                          })
                                    })
                              })
                        ]
                      }, ID.toString(resource.id));
          }));
  var resources$3 = state.status;
  var tmp$4;
  if (typeof resources$3 !== "object") {
    tmp$4 = null;
  } else {
    var resources$4 = resources$3._0;
    tmp$4 = JsxRuntime.jsx(ResourcesDashboardActionBar.make, {
          selection: Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(state.selection), (function (id) {
                      return Belt_Option.map((function (__x) {
                                      return Js_array.find((function (resource) {
                                                    return Caml_obj.equal(id, resource.id);
                                                  }), __x);
                                    })(resources$4), (function (resource) {
                                    return resource.active;
                                  }));
                    })), [], (function (acc, active) {
                  if (active !== undefined) {
                    return Belt_Array.concat(acc, [active]);
                  } else {
                    return acc;
                  }
                })),
          error: state.batchUpdateError,
          busy: Belt_Option.isSome(state.batchUpdate),
          deselect: (function () {
              dispatch("DeselectAll");
            }),
          activate: (function (param) {
              dispatch("ActivateInBatch");
            }),
          deactivate: (function (param) {
              dispatch("DeactivateInBatch");
            })
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                state.openDestroyConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: DashboardDeleteConfirmationModalCss.text
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch("CloseDestroyConfirm");
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var resourceId = state.currentResourceId;
                                                  if (resourceId !== undefined) {
                                                    return $$Promise.wait(Api.destroyResource(Caml_option.valFromOption(resourceId)), (function (x) {
                                                                  if (x.TAG === "Ok") {
                                                                    return Url.visit(Routes_Resource.Dashboard.index);
                                                                  } else {
                                                                    return SentryLogger.error1({
                                                                                rootModule: "ResourcesDashboard",
                                                                                subModulePath: /* [] */0,
                                                                                value: "default",
                                                                                fullPath: "ResourcesDashboard.default"
                                                                              }, "DestroyResource::Error", [
                                                                                "Error",
                                                                                x._0
                                                                              ]);
                                                                  }
                                                                }));
                                                  } else {
                                                    return dispatch("CloseDestroyConfirm");
                                                  }
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: DashboardDeleteConfirmationModalCss.buttons
                                    })
                              ],
                              className: DashboardDeleteConfirmationModalCss.dialog
                            }),
                        className: DashboardDeleteConfirmationModalCss.overlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null,
                JsxRuntime.jsx(ResourceDashboardNavbar.make, {
                      selectedCategory: "Resources"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: DashboardIndexCss.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: DashboardIndexCss.title,
                                      children: "Resources"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(SearchField.make, {
                                              id: "resources-search",
                                              value: state.search,
                                              placeholder: "Search resources",
                                              inputClassName: DashboardFilterCss.searchField,
                                              onChange: (function ($$event) {
                                                  dispatch({
                                                        TAG: "UpdateSearchInput",
                                                        _0: $$event.target.value
                                                      });
                                                })
                                            }),
                                        tmp,
                                        JsxRuntime.jsx(ResourcesOptions.make, {})
                                      ],
                                      className: DashboardIndexCss.headerControls
                                    })
                              ],
                              className: DashboardIndexCss.header
                            }),
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsxs(Table.make, {
                                    className: DashboardTableCss.table,
                                    children: [
                                      JsxRuntime.jsx(TableHeader.make, {
                                            children: JsxRuntime.jsxs(TableRow.make, {
                                                  children: [
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Checkbox.make, {
                                                                id: "resource--all--selected",
                                                                size: "SM",
                                                                checked: tmp$1,
                                                                onChange: (function (param) {
                                                                    dispatch("ToggleSelectionOfAllItems");
                                                                  })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByTitle");
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Resource",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$1 === "object" && match$1.NAME === "Title" ? match$1.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: tmp$2
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByCreatedAt");
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Created At",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$3 === "object" && match$3.NAME === "CreatedAt" ? match$3.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByPopular");
                                                                  }),
                                                                children: JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        "Popular",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$4 === "object" && match$4.NAME === "Popular" ? match$4.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: "Active"
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: ""
                                                        })
                                                  ]
                                                })
                                          }),
                                      JsxRuntime.jsx(TableBody.make, {
                                            children: tmp$3
                                          })
                                    ]
                                  }),
                              className: DashboardTableCss.tableWrapper
                            }),
                        state.totalPages > 1 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (x) {
                                          dispatch({
                                                TAG: "UpdatePage",
                                                _0: x
                                              });
                                        })
                                    }),
                                className: DashboardTableCss.pagination
                              }) : null
                      ]
                    }),
                tmp$4
              ]
            });
}

var Modal;

var Css;

var TableCss;

var Filters;

var $$default = ResourcesDashboard$default;

export {
  Modal ,
  Css ,
  TableCss ,
  Filters ,
  initialState ,
  $$default as default,
}
/* initialState Not a pure module */
